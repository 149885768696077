import restaurant from "./images/rest.jpeg";
import biserica from "./images/bis2.jpg";
import img_card from "./images/baby.jpg";
import imgheadermb from "./images/m7.png";
import imgheader from "./images/m2.png";
import imgheadermiini from "./images/bal4.webp";
import imgheadermiinimb from "./images/bal4.webp";
import logo from './images/bal.gif'



const data = {
    introData: [{
        copilul: "Eva-Elena",
        familia: "familia Lucaci",
        logo: logo,
        tata: "Constantin",
        mama: "Angela",
        data: "23 Septembrie 2023",
        data_confirmare: "10 septembrie 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "angilucaci2@gmail.com", 
       tel: "+353899835160",
       phone: "tel:+353899835160",
       viber: "viber://chat?number=%2B353899835160",
       whatsapp: "https://wa.me/+353899835160",
       messenger: "https://www.messenger.com/t/angel.ica.1276",
       tel1: "+353871423563",
       phone1: "tel:+353871423563",
       viber1: "viber://chat?number=%2B353871423563",
       whatsapp1: "https://wa.me/+353871423563",
       messenger1: "https://www.messenger.com/t/constantin.lucaci.3",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica Ortodoxă",
            name: "Sfântul Columba",
            data: "23 septembrie 2023, sâmbătă, ora 14:00",
            adress: "Blakestown Way, Blakestown, Dublin 15, Irlanda ",
            harta: "https://goo.gl/maps/u8yBZLiuSFSor94G8",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Elias",
            data: "23 septembrie 2023, sâmbătă, ora 18:00",
            adress: "Alsaa Club, Swords Road, Swords, Co. Dublin, Eircode K67 YV06",
            harta: "https://goo.gl/maps/ctS43iBBoYMx3VQ5A",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Am sosit dintr-o minune \n Sau dintr-un vis, aș putea spune \n Părinții mei mult m-au dorit \n Și visul eu le-am împlinit!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;